.singlePhoto {
    height: 100%;
    object-fit: cover;
}

.side-bar {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
}

.summary-line span {
    margin: 5px
}

.only-for-owner button {
    margin: 5px
}

.photos {
    height: 435px;
    width: 100vw;
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;
}

.top-section {
    position: relative;

}

.summary-line {
    position: absolute;
    font-weight: 700;
    color: white;
    top: 150px;
    margin: 50px;
}

.see-all-photos button {
    position: absolute;
    bottom: 50px;
    right: 50px;
    font-weight: 900;
    background-color: transparent;
    color: white;
    font-size: 16px;
    padding: 12px 32px 12px 32px;
    border: 4px solid white;
    border-radius: 4px;
}

.see-all-photos button:hover {
    cursor: pointer;
}

h1 {
    font-size: 48px;
    margin: 10px;
    white-space: nowrap;
}

.one-line-review {
    display: flex;
    align-items: center;
}

.price {
    margin: 10px;
}

.single-green-word {
    color: rgb(4, 197, 133);
}

.buttons {
    margin: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(211, 211, 211);
}

.buttons button {
    color: #2d2e2f;
    background-color: initial;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    font-weight: 700;
    text-align: center;
    font-size: 16px;
    transition: 0.5s;
}

.buttons button:hover {
    cursor: pointer;
    background-color: #e6e6e6;
}

.bottom-section {
    width: 70%;
    display: flex;
    justify-content: center;
    gap: 50px;
}

.bottom-left-section {
    margin-right: 20px;
}

.side-bar {
    border: 1px solid rgb(211, 211, 211);
    border-radius: 4px;
    padding: 10px;
    margin-top: 20px;
    font-weight: 600;
    color: rgb(110, 112, 114);
    max-width: 365px;
}

.bottom-border {
    padding: 20px;
}

.website-link {
    padding-left: 10px;
}

a {
    color: rgb(0, 0, 0);
    font-weight: 700;
    font-size: 16px;
}

.single-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
}


.amenities {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


.indi-amenity {
    flex-basis: 40%;
    margin: 10px;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    align-items: center;
}

.indi-amenity .icon_svg {
    margin: 5px;
}

.website-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(211, 211, 211);
}

.restaurant-description {
    font-size: 16px;
    font-weight: 400;
    color: rgb(45, 46, 47);
    line-height: 1.6;
    margin-top: 20px;
    max-width: 988px; /* Set a maximum width for the container */
    overflow-wrap: break-word;
}

.phone-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0;
    padding-left: 10px;
    border-bottom: 1px solid rgb(211, 211, 211);
}

.address-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0;
    padding-left: 10px;
    margin-top: 10px;
}

.bottom-left-section {
    margin-left: 40px;
}

.map-direction {
    margin-top: 20px;
}

.map-link {
    margin-left: 10px;
}

.address-icon_svg {
    position: relative;
    bottom: 10px;
}

button:hover {
    cursor: pointer;
}
