.add-restaurant-button{
    display: flex;
    flex-direction: row-reverse;
}

.add-restaurant-button button {
    color: #2d2e2f;
    background-color: initial;
    border: 2px solid #d3d3d3;
    border-radius: 5px;
    font-weight: 700;
    text-align: center;
    font-size: 16px;
    transition: 0.5s;
}

.add-restaurant-button button:hover {
    cursor: pointer;
    background-color: #e6e6e6;
}

.restaurant-list a {
    margin-left: auto;
    margin-right: auto;
}
