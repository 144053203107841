.update-review-container{
    width:550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }

  .update-review-container label{
    display: flex;
    padding: 0.2em 0
  }

  .update-review-container span{
    width: 80px;
    margin-left: 10px;
  }

  .update-review-container input{
    width: 167px;
  }

  .update-review-container select{
    width: 175px;
  }

  .update-new-review-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid grey;
    width:280px;
    border-radius: 10px;
  }


  .update-review-container button:hover {
    background-color: lightgray;
  }
