.profile-dropdown {
  position: absolute;
  right: 5px;
  top: 60px;
  width: 150px;
  list-style-type: none;
  background-color: white;
  border-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 18px;
  padding-right: 12px;
  z-index: 10;
  box-shadow: 4px 2px 10px rgb(166, 162, 162);
}

.user-dropdown-username {
  display:flex;
  justify-content: center;
}

.user-dropdown-email {
  display:flex;
  justify-content: center;
}

.hidden {
  display: none;
}

.logo-img {
  width: 120px;
  height: 20%;
  top: 30px;
}

.navBar-container {
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid rgb(211, 211, 211);
}

.navigationBar {
  font-family: Helvetica;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 917px;
}

a.visited {
  text-decoration: none;
  color: black;
}

.nav {
  width: auto;
  max-width: 90rem;
  margin: 0 auto;
}

.log-out-div-button {
  padding-top: 8px;
  border-top: 1px solid rgb(211, 211, 211);
  margin-left: 7px;
}

.fa-solid {
  color: white;
  font-size: 1.3rem;
}

.navBar-container {
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid rgb(211, 211, 211);
}

.homeButton {
  color: black;
}

.login-button {
  font-family: Helvetica;
  text-decoration: none;
  color: black;
  border: 1px solid rgb(37, 37, 37);
  padding: none;
  border-radius: 4px;
  background-color: white;
  margin-left: 10px;
  margin-right: 10px;
  width: 90px;
  height: 30px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-button {
  font-family: Helvetica;
  text-decoration: none;
  color: white;
  border: 1px solid red;
  background-color: red;
  border-radius: 4px;
  width: 90px;
  height: 30px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-button-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-button-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileButton {
  padding: 15px;
  margin-top: -5px;
  margin-left: 4px;
  border-radius: 50%;
  border: none;
  background-color: rgb(200, 200, 200);
  color: grey;
}

.profileButton:hover {
  cursor: pointer;
}

.navBar-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user-logout-button {
  border: none;
  background-color: white;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.fa-solid.fa-arrow-right-from-bracket {
  color: black
}

.user-dropdown-email {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.user-dropdown-username {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.profdropdownbutt {
  display: flex;
  padding: 8px;
  margin: 20px;
  margin-left: 30px;
  margin-right: 20px;
  color: #2d2e2f;
  background-color: initial;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  font-weight: 700;
  text-align: center;
  font-size: 16px;
  transition: 0.5s;
}

.profdropdownbutt:hover {
  /* background-color: rgb(226, 13, 13); */
  background-color: #e6e6e6;
  /* color: white; */
  cursor: pointer;
}

.search-button {
  background-color: red;
  border: none;
  height: 100%;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  position: absolute;
  right: 0;
  margin: 0;
}

.search-input-values {
  border: transparent solid;
  padding: 8px 50px 8px 8px;
  position: absolute;
  top: 6.5px;
  left: 20px;
  width: 365px;
}

.search-input-values:focus {
  outline: none;
}

.search-bar-form {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.nav-search-container {
  box-shadow: 5px 1px 12px rgb(166, 162, 162);
  width: 150px;
  position: relative;
  margin: 4px 10px 0px 5px;
  border: none;
  border-radius: 10px;
  width: 500px;
  display: flex;
  align-items: center;
}
