.logintext{
    color:rgb(226, 13, 13);
    font-size: 35px;
    font-weight: bold;
    position: relative;
    display: flex;
    margin-top: 90px;
    margin-left: 195px;
}

.errors {
    height: 70px;
}

.login-error {
    position: relative;
    top: 100px;
    display: flex;
    justify-content: flex-start;
    font-weight: bolder;
    font-size: large;
    left: 27px;
    color: red;
}

.autharea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 80px;
}

.authdeets {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.inputdeet {
    padding: .45em;
    border: .4px solid grey;
    width: 98%;
    border-radius: 3px;
    margin: .51em .51em .51em 0px;
}

.redcarpetpic {
    display: flex;
    align-items: flex-start;
    margin-right: -8%;
}

.sibutton2 {
    display: flex;
    padding: .5em;
    border: .3px solid grey;
    width: 106%;
    border-radius: 3px;
    margin: .5em 0px ;
    background-color:rgb(226, 13, 13);
    font-size: medium;
    font-weight: bolder;
    color: white;
    justify-content: center;
}

.sibutton2:hover {
    background-color: rgba(205, 10, 10, 1);
    cursor: pointer;
}
